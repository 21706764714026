div.__commenter_avatar {
  width: 48px !important;

  img.__avatar {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
}
