.__page_wrapper {
  height: 100%;
  overflow-y: auto;
  
  &.__bg1 {
    background-image: url("../../assets/welcome-page-desktop-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.__bg2 {
    background-image: url("../../assets/ready-to-play-desktop-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  header>div>a>img.__logo {
    width: 50px;
  }

  @media (min-width: 992px) {
    header>div>a>img.__logo {
      width: 75px;
    }
  }
}
