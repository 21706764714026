@import "../../../theme/theme";

div.__profile_page {
  width: 100%;

  div.__plans {
    div.__plan {
      min-width: 30%;
      div {
        border-radius: 0.5rem !important;
      }
    }
  }

  div.__avatar_wrapper {
    height: 200px;
    width: 200px;

    img.__avatar {
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 50%;
    }
  }
}
