@import "./theme/theme";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $grey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: darken($color: $grey, $amount: 20);
}
