// top
// #region small
.top-0,
.top-sm-0 {
  top: 0% !important;
}
.top-10,
.top-sm-10 {
  top: 10% !important;
}
.top-20,
.top-sm-20 {
  top: 20% !important;
}
.top-30,
.top-sm-30 {
  top: 30% !important;
}
.top-40,
.top-sm-40 {
  top: 40% !important;
}
.top-50,
.top-sm-50 {
  top: 50% !important;
}
.top-60,
.top-sm-60 {
  top: 60% !important;
}
.top-70,
.top-sm-70 {
  top: 70% !important;
}
.top-80,
.top-sm-80 {
  top: 80% !important;
}
.top-90,
.top-sm-90 {
  top: 90% !important;
}
.top-100,
.top-sm-100 {
  top: 100% !important;
}
// #endregion
// medium
@media (min-width: 768px) {
  .top-md-0 {
    top: 0% !important;
  }
  .top-md-10 {
    top: 10% !important;
  }
  .top-md-20 {
    top: 20% !important;
  }
  .top-md-30 {
    top: 30% !important;
  }
  .top-md-40 {
    top: 40% !important;
  }
  .top-md-50 {
    top: 50% !important;
  }
  .top-md-60 {
    top: 60% !important;
  }
  .top-md-70 {
    top: 70% !important;
  }
  .top-md-80 {
    top: 80% !important;
  }
  .top-md-90 {
    top: 90% !important;
  }
  .top-md-100 {
    top: 100% !important;
  }
}
// large
@media (min-width: 992px) {
  .top-lg-0 {
    top: 0% !important;
  }
  .top-lg-10 {
    top: 10% !important;
  }
  .top-lg-20 {
    top: 20% !important;
  }
  .top-lg-30 {
    top: 30% !important;
  }
  .top-lg-40 {
    top: 40% !important;
  }
  .top-lg-50 {
    top: 50% !important;
  }
  .top-lg-60 {
    top: 60% !important;
  }
  .top-lg-70 {
    top: 70% !important;
  }
  .top-lg-80 {
    top: 80% !important;
  }
  .top-lg-90 {
    top: 90% !important;
  }
  .top-lg-100 {
    top: 100% !important;
  }
}

// bottom
// #region small
.bottom-0,
.bottom-sm-0 {
  bottom: 0% !important;
}
.bottom-10,
.bottom-sm-10 {
  bottom: 10% !important;
}
.bottom-20,
.bottom-sm-20 {
  bottom: 20% !important;
}
.bottom-30,
.bottom-sm-30 {
  bottom: 30% !important;
}
.bottom-40,
.bottom-sm-40 {
  bottom: 40% !important;
}
.bottom-50,
.bottom-sm-50 {
  bottom: 50% !important;
}
.bottom-60,
.bottom-sm-60 {
  bottom: 60% !important;
}
.bottom-70,
.bottom-sm-70 {
  bottom: 70% !important;
}
.bottom-80,
.bottom-sm-80 {
  bottom: 80% !important;
}
.bottom-90,
.bottom-sm-90 {
  bottom: 90% !important;
}
.bottom-100,
.bottom-sm-100 {
  bottom: 100% !important;
}
// #endregion
// medium
@media (min-width: 768px) {
  .bottom-md-0 {
    bottom: 0% !important;
  }
  .bottom-md-10 {
    bottom: 10% !important;
  }
  .bottom-md-20 {
    bottom: 20% !important;
  }
  .bottom-md-30 {
    bottom: 30% !important;
  }
  .bottom-md-40 {
    bottom: 40% !important;
  }
  .bottom-md-50 {
    bottom: 50% !important;
  }
  .bottom-md-60 {
    bottom: 60% !important;
  }
  .bottom-md-70 {
    bottom: 70% !important;
  }
  .bottom-md-80 {
    bottom: 80% !important;
  }
  .bottom-md-90 {
    bottom: 90% !important;
  }
  .bottom-md-100 {
    bottom: 100% !important;
  }
}
// large
@media (min-width: 992px) {
  .bottom-lg-0 {
    bottom: 0% !important;
  }
  .bottom-lg-10 {
    bottom: 10% !important;
  }
  .bottom-lg-20 {
    bottom: 20% !important;
  }
  .bottom-lg-30 {
    bottom: 30% !important;
  }
  .bottom-lg-40 {
    bottom: 40% !important;
  }
  .bottom-lg-50 {
    bottom: 50% !important;
  }
  .bottom-lg-60 {
    bottom: 60% !important;
  }
  .bottom-lg-70 {
    bottom: 70% !important;
  }
  .bottom-lg-80 {
    bottom: 80% !important;
  }
  .bottom-lg-90 {
    bottom: 90% !important;
  }
  .bottom-lg-100 {
    bottom: 100% !important;
  }
}

// start
// #region small
.start-0,
.start-sm-0 {
  left: 0% !important;
}
.start-10,
.start-sm-10 {
  left: 10% !important;
}
.start-20,
.start-sm-20 {
  left: 20% !important;
}
.start-30,
.start-sm-30 {
  left: 30% !important;
}
.start-40,
.start-sm-40 {
  left: 40% !important;
}
.start-50,
.start-sm-50 {
  left: 50% !important;
}
.start-60,
.start-sm-60 {
  left: 60% !important;
}
.start-70,
.start-sm-70 {
  left: 70% !important;
}
.start-80,
.start-sm-80 {
  left: 80% !important;
}
.start-90,
.start-sm-90 {
  left: 90% !important;
}
.start-100,
.start-sm-100 {
  left: 100% !important;
}
// #endregion
// medium
@media (min-width: 768px) {
  .start-md-0 {
    left: 0% !important;
  }
  .start-md-10 {
    left: 10% !important;
  }
  .start-md-20 {
    left: 20% !important;
  }
  .start-md-30 {
    left: 30% !important;
  }
  .start-md-40 {
    left: 40% !important;
  }
  .start-md-50 {
    left: 50% !important;
  }
  .start-md-60 {
    left: 60% !important;
  }
  .start-md-70 {
    left: 70% !important;
  }
  .start-md-80 {
    left: 80% !important;
  }
  .start-md-90 {
    left: 90% !important;
  }
  .start-md-100 {
    left: 100% !important;
  }
}
// large
@media (min-width: 992px) {
  .start-lg-0 {
    left: 0% !important;
  }
  .start-lg-10 {
    left: 10% !important;
  }
  .start-lg-20 {
    left: 20% !important;
  }
  .start-lg-30 {
    left: 30% !important;
  }
  .start-lg-40 {
    left: 40% !important;
  }
  .start-lg-50 {
    left: 50% !important;
  }
  .start-lg-60 {
    left: 60% !important;
  }
  .start-lg-70 {
    left: 70% !important;
  }
  .start-lg-80 {
    left: 80% !important;
  }
  .start-lg-90 {
    left: 90% !important;
  }
  .start-lg-100 {
    left: 100% !important;
  }
}

// end
// #region small
.end-0,
.end-sm-0 {
  right: 0% !important;
}
.end-10,
.end-sm-10 {
  right: 10% !important;
}
.end-20,
.end-sm-20 {
  right: 20% !important;
}
.end-30,
.end-sm-30 {
  right: 30% !important;
}
.end-40,
.end-sm-40 {
  right: 40% !important;
}
.end-50,
.end-sm-50 {
  right: 50% !important;
}
.end-60,
.end-sm-60 {
  right: 60% !important;
}
.end-70,
.end-sm-70 {
  right: 70% !important;
}
.end-80,
.end-sm-80 {
  right: 80% !important;
}
.end-90,
.end-sm-90 {
  right: 90% !important;
}
.end-100,
.end-sm-100 {
  right: 100% !important;
}
// #endregion
// medium
@media (min-width: 768px) {
  .end-lg-0 {
    right: 0% !important;
  }
  .end-lg-10 {
    right: 10% !important;
  }
  .end-md-20 {
    right: 20% !important;
  }
  .end-md-30 {
    right: 30% !important;
  }
  .end-md-40 {
    right: 40% !important;
  }
  .end-md-50 {
    right: 50% !important;
  }
  .end-md-60 {
    right: 60% !important;
  }
  .end-md-70 {
    right: 70% !important;
  }
  .end-md-80 {
    right: 80% !important;
  }
  .end-md-90 {
    right: 90% !important;
  }
  .end-md-100 {
    right: 100% !important;
  }
}
// large
@media (min-width: 992px) {
  .end-lg-0 {
    right: 0% !important;
  }
  .end-lg-10 {
    right: 10% !important;
  }
  .end-lg-20 {
    right: 20% !important;
  }
  .end-lg-30 {
    right: 30% !important;
  }
  .end-lg-40 {
    right: 40% !important;
  }
  .end-lg-50 {
    right: 50% !important;
  }
  .end-lg-60 {
    right: 60% !important;
  }
  .end-lg-70 {
    right: 70% !important;
  }
  .end-lg-80 {
    right: 80% !important;
  }
  .end-lg-90 {
    right: 90% !important;
  }
  .end-lg-100 {
    right: 100% !important;
  }
}

// height
// #region small
.h-0,
.h-sm-0 {
  height: 0% !important;
}
.h-10,
.h-sm-10 {
  height: 10% !important;
}
.h-20,
.h-sm-20 {
  height: 20% !important;
}
.h-30,
.h-sm-30 {
  height: 30% !important;
}
.h-40,
.h-sm-40 {
  height: 40% !important;
}
.h-50,
.h-sm-50 {
  height: 50% !important;
}
.h-60,
.h-sm-60 {
  height: 60% !important;
}
.h-70,
.h-sm-70 {
  height: 70% !important;
}
.h-80,
.h-sm-80 {
  height: 80% !important;
}
.h-90,
.h-sm-90 {
  height: 90% !important;
}
.h-100,
.h-sm-100 {
  height: 100% !important;
}
// #endregion
// medium
@media (min-width: 768px) {
  .h-md-0 {
    height: 0% !important;
  }
  .h-md-10 {
    height: 10% !important;
  }
  .h-md-20 {
    height: 20% !important;
  }
  .h-md-30 {
    height: 30% !important;
  }
  .h-md-40 {
    height: 40% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-60 {
    height: 60% !important;
  }
  .h-md-70 {
    height: 70% !important;
  }
  .h-md-80 {
    height: 80% !important;
  }
  .h-md-90 {
    height: 90% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
}
// large
@media (min-width: 992px) {
  .h-lg-0 {
    height: 0% !important;
  }
  .h-lg-10 {
    height: 10% !important;
  }
  .h-lg-20 {
    height: 20% !important;
  }
  .h-lg-30 {
    height: 30% !important;
  }
  .h-lg-40 {
    height: 40% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-60 {
    height: 60% !important;
  }
  .h-lg-70 {
    height: 70% !important;
  }
  .h-lg-80 {
    height: 80% !important;
  }
  .h-lg-90 {
    height: 90% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
}

// viewport height
// #region small
.vh-0,
.vh-sm-0 {
  height: 0vh !important;
}
.vh-10,
.vh-sm-10 {
  height: 10vh !important;
}
.vh-20,
.vh-sm-20 {
  height: 20vh !important;
}
.vh-30,
.vh-sm-30 {
  height: 30vh !important;
}
.vh-40,
.vh-sm-40 {
  height: 40vh !important;
}
.vh-50,
.vh-sm-50 {
  height: 50vh !important;
}
.vh-60,
.vh-sm-60 {
  height: 60vh !important;
}
.vh-70,
.vh-sm-70 {
  height: 70vh !important;
}
.vh-80,
.vh-sm-80 {
  height: 80vh !important;
}
.vh-90,
.vh-sm-90 {
  height: 90vh !important;
}
.vh-100,
.vh-sm-100 {
  height: 100vh !important;
}
// #endregion
// medium
@media (min-width: 768px) {
  .vh-md-0 {
    height: 0vh !important;
  }
  .vh-md-10 {
    height: 10vh !important;
  }
  .vh-md-20 {
    height: 20vh !important;
  }
  .vh-md-30 {
    height: 30vh !important;
  }
  .vh-md-40 {
    height: 40vh !important;
  }
  .vh-md-50 {
    height: 50vh !important;
  }
  .vh-md-60 {
    height: 60vh !important;
  }
  .vh-md-70 {
    height: 70vh !important;
  }
  .vh-md-80 {
    height: 80vh !important;
  }
  .vh-md-90 {
    height: 90vh !important;
  }
  .vh-md-100 {
    height: 100vh !important;
  }
}
// large
@media (min-width: 992px) {
  .vh-lg-0 {
    height: 0vh !important;
  }
  .vh-lg-10 {
    height: 10vh !important;
  }
  .vh-lg-20 {
    height: 20vh !important;
  }
  .vh-lg-30 {
    height: 30vh !important;
  }
  .vh-lg-40 {
    height: 40vh !important;
  }
  .vh-lg-50 {
    height: 50vh !important;
  }
  .vh-lg-60 {
    height: 60vh !important;
  }
  .vh-lg-70 {
    height: 70vh !important;
  }
  .vh-lg-80 {
    height: 80vh !important;
  }
  .vh-lg-90 {
    height: 90vh !important;
  }
  .vh-lg-100 {
    height: 100vh !important;
  }
}

// width
// #region small
.w-0,
.w-sm-0 {
  width: 0% !important;
}
.w-10,
.w-sm-10 {
  width: 10% !important;
}
.w-20,
.w-sm-20 {
  width: 20% !important;
}
.w-30,
.w-sm-30 {
  width: 30% !important;
}
.w-40,
.w-sm-40 {
  width: 40% !important;
}
.w-50,
.w-sm-50 {
  width: 50% !important;
}
.w-60,
.w-sm-60 {
  width: 60% !important;
}
.w-70,
.w-sm-70 {
  width: 70% !important;
}
.w-80,
.w-sm-80 {
  width: 80% !important;
}
.w-90,
.w-sm-90 {
  width: 90% !important;
}
.w-100,
.w-sm-100 {
  width: 100% !important;
}
// #endregion
// medium
@media (min-width: 768px) {
  .w-md-0 {
    width: 0% !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
}
// large
@media (min-width: 992px) {
  .w-lg-0 {
    width: 0% !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
}

// Font Weight
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-600 {
  font-weight: 600;
}

// cursor
.cursor-pointer {
  cursor: pointer;
}
