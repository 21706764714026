@import "../../../theme/theme";

div.__profile_page {
  width: 100%;

  div.__actions {
    a.__action {
      width: 30%;
      min-width: 250px;
      border-radius: 0.5rem !important;
    }
  }

  div.__avatar_wrapper {
    height: 200px;
    width: 200px;

    img.__avatar {
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 50%;
    }
  }

  div.__spicy_units_wrapper
    > div.__spicy_units
    > div.__spicy_unit_icon_wrapper {
    height: 24px;
    width: 24px;

    img.__spicy_unit_icon {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
