@import "../../../theme/theme";

div.__profile_page {
  width: 100%;

  div.__actions {
    a.__action {
      border-radius: 0.5rem !important;

      @media (min-width: 992px) {
        max-width: 30%;
        min-width: 250px;
        width: 30%;
      }
    }
  }

  div.__avatar_wrapper {
    height: 200px;
    width: 200px;

    img.__avatar {
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 50%;
    }
  }
}
