
// set theme color variables
$primary: #6B2B14;
$secondary: #E89222;
$light: #FDF4E8;
$dark: #2B1108;
$grey: #B1ACAB;
$cream: #F5D1A3;
$active: #562310;
$success: #3E8D63;
$warning: #FFC266;
$danger: #f02517;

// small = 576 *override default (smallest)
// medium = 768 *override small
// large = 992 *override medium