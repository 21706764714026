@import "../../../theme/theme";

div.__tutorial_page {
  width: 100%;

  div.__tutorial_content_wrapper {
    width: 100%;

    video.__lesson_video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  div.__comment_input_wrapper {
    div.__comment_input {
      width: calc(100% - 24px);
    }
  }

  @media (min-width: 992px) {
    div.__comment_input_wrapper {
      div.__comment_input {
        width: calc(100% - 438px) !important;
      }
    }
  }

}
